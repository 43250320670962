import React from "react";

import { Wrapper } from "./Indicator.styles";

const Indicator = (props) => {
  //PROPS
  const { slide, data } = props;
  //PROPS

  return (
    <Wrapper>
      <div className="info">
        <span>{slide + 1}</span>
        <img src="/images/line.svg" alt="line" />
        <img src="/images/line_green.svg" className="mobile" alt="line" />
        <span>{data.length}</span>
      </div>
    </Wrapper>
  );
};

export default Indicator;

import React, { useRef, useState } from "react";

import HTMLReactParser from "html-react-parser";
import Slider from "react-slick";
import Lottie from "react-lottie";
import Img from "gatsby-image";

import { Wrapper, Card, Controls } from "./Alerts.styles";
import Indicator from "./indicator";
import { green } from "theme/colors";
import { Arrow } from "../icons";

import swipe from "components/lotties/swipe.json";

const Alerts = (props) => {
  //PROPS
  const {
    data: { alerts }
  } = props;
  //PROPS

  //STATES
  const [slide, setSlide] = useState(0);
  //STATES

  //REF
  const slider = useRef(null);
  //REF

  //FUNCS
  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };
  //FUNCS

  //DEFS
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    draggable: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    swipe: true,
    fade: false,
    slidesToScroll: 1,
    swipeToSlide: false,
    autoplay: false,
    autoplaySpeed: 4000,
    afterChange: (e) => {
      setSlide(e);
    },
    beforeChange: (e) => {
      document.querySelector(".lottie").classList.add("lottie-hide");
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: swipe,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  //DEFS
  return (
    <Wrapper>
      <div className="lottie">
        <Lottie options={defaultOptions} />
      </div>
      <Slider {...settings} ref={slider}>
        {alerts.map((elem, index) => {
          let image = elem.image?.localFile?.childImageSharp?.fluid;
          return (
            <div className="slide " key={index}>
              <Card data-index={index}>
                <Controls>
                  {slide >= 1 && (
                    <button
                      id="al-prev"
                      className="prev"
                      onClick={() => prevSlide()}
                      aria-label="Ir para próximo anterior"
                    >
                      <Arrow color={green.default} />
                    </button>
                  )}
                  {slide < alerts.length - 1 && (
                    <button
                      id="al-next"
                      onClick={() => nextSlide()}
                      aria-label="Ir para Alerta seguinte"
                    >
                      <Arrow color={green.default} />
                    </button>
                  )}
                </Controls>
                <Img fluid={image} alt={elem.image?.altText} />
                <div className="card-text">
                  {elem.text && HTMLReactParser(elem.text)}
                </div>
              </Card>
            </div>
          );
        })}
      </Slider>
      <Indicator
        slide={slide}
        nextSlide={nextSlide}
        prevSlide={prevSlide}
        data={alerts}
      />
    </Wrapper>
  );
};

export default Alerts;

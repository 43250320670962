import styled from "styled-components";
import { green } from "theme/colors";
import { breakpoints, toRem } from "utils/mixins";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  * {
    outline: none !important;
  }
  .lottie {
    max-width: 200px;
    z-index: 5;
    position: absolute;
    top: 55%;
    left: 50%;
    transition: 250ms;
    transform: translateY(-100%) translateX(-50%);
    &-hide {
      visibility: none;
      opacity: 0;
    }
    @media ${breakpoints.laptop} {
      display: none;
    }
  }
`;

export const Card = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  background: ${(props) => props.color};
  position: relative;
  &:nth-child(1) {
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(0);
  }
  &.show {
    /* transform: translateY(0); */
  }
  .gatsby-image-wrapper {
    // order: 2;
    position: relative;
    width: 100vw;
    height: 60vh;
    object-fit: cover;
  }

  .card-text {
    height: 40vh;
    overflow-y: auto;
    padding: ${toRem(20)};
    width: 100%;
    background-color: white;
    position: relative;
    p {
      margin-top: 0;
      color: ${green.default};
      font-family: "Futura PT";
      font-weight: bold;
      font-style: normal;
      font-size: ${toRem(20)};
      line-height: ${toRem(23)};
      letter-spacing: ${toRem(0.2)};
      a {
        text-decoration: underline;
      }
    }
  }
  @media ${breakpoints.laptop} {
    display: flex;
    padding-top: 0;
    flex-direction: row;
    overflow: hidden;
    .gatsby-image-wrapper {
      order: 1;
      flex: unset;
      width: 50vw;
      height: 100vh;
    }
    .card-text {
      overflow: hidden;
      order: 2;
      max-width: ${toRem(700)};
      height: 100vh;
      padding: 0;
      width: 50vw;
      padding-left: ${toRem(150)};
      padding-right: ${toRem(20)};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  top: unset;
  bottom: 0;
  left: 20px;
  z-index: 2;
  padding: 20px 50px;
  button {
    display: none;
    top: 0;
    right: 0;
    position: absolute;
    padding: 10px;
    svg {
      width: 20px;
    }
    transform: rotate(-90deg);
    &.prev {
      right: unset;
      left: 0;
      transform: rotate(90deg);
    }
  }

  @media ${breakpoints.laptop} {
    display: block;
    bottom: unset;
    top: 100px;
    left: calc(50vw + 150px);
    button {
      display: block;
    }
  }
  @media (min-width: 1024px) and (min-height: 500px) {
    top: 140px;
  }
  @media (min-width: 1024px) and (min-height: 700px) {
    top: 200px;
  }
`;

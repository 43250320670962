import React, { useEffect } from "react";

import Alerts from "components/alerts";

const AlertsTemplate = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: { alerts }
    }
  } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    setColor(true);

    return () => {
      setColor(false);
    };
  }, [dimensions]);
  //EFFECTS

  return <Alerts data={alerts} />;
};

export const query = graphql`
  query Alerts($id: String) {
    page: wpPage(id: { eq: $id }) {
      alerts {
        alerts {
          text
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AlertsTemplate;

import styled from "styled-components";
import { p_alternative } from "theme/typography";
import { breakpoints } from "utils/mixins";

export const Wrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #398b59;
  .info {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      display: none;
      position: relative;
      transform: rotate(90deg);
      width: 2px;
      &.mobile {
        display: block;
      }
    }
    span {
      ${p_alternative}
      font-size: 18px;
      line-height: 18px;
      position: relative;
      display: block;
    }
  }
  @media ${breakpoints.laptop} {
    color: white;
    //padding: 20px 40px;
    top: 50%;
    //left: 50px;
    transform: translateY(-50%) rotate(-90deg);
    .info {
      img {
        display: block;
        &.mobile {
          display: none;
        }
      }
    }
  }
  @media ${breakpoints.laptop} {
    //padding: 20px 40px;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-90deg);
  }
  @media (min-width: 1400px) {
    //bottom: 200px;
    right: calc(700px + 50vw);
    left: unset;
    transform: translateX(100%) translateY(-50%) rotate(-90deg);
  }
  @media (min-width: 1921px) {
    right: unset;
    left: 15vw;
    transform: translateY(-50%) rotate(-90deg);
  }
`;
